var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            { staticClass: "mx-auto", staticStyle: { width: "90%" } },
            [
              _c(
                "v-row",
                [
                  _c("v-card-title", { staticClass: "font-weight-bold pb-3" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("title.contractor.uploadRequiredDocuments")
                        ) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _c("v-row", { staticClass: "pl-2" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("description.requiredDocuments.explanation1"))
                  ),
                ]),
              ]),
              _c("v-row", { staticClass: "pl-2 font-weight-black" }, [
                _c("p", { staticStyle: { margin: "3px" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("description.requiredDocuments.paper.title")
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("v-row", { staticClass: "pl-4" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("description.requiredDocuments.paper.text")
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("v-row", { staticClass: "pl-2 font-weight-black" }, [
                _c("p", { staticStyle: { margin: "3px" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("description.requiredDocuments.data.title")
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("v-row", { staticClass: "pl-4" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("description.requiredDocuments.data.text")
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("v-row", { staticClass: "pl-2" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("description.requiredDocuments.explanation2"))
                  ),
                ]),
              ]),
              _c("v-row", { staticClass: "pl-2" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("description.requiredDocuments.backCaution"))
                  ),
                ]),
              ]),
              _c("v-row", { staticClass: "pl-2 pt-4 font-weight-bold" }, [
                _c("p", { staticClass: "ma-0" }, [
                  _vm._v(_vm._s(_vm.$t("label.requiredDocumentsList"))),
                ]),
              ]),
              _vm._l(_vm.requiredDocumentsMessage, function (message, index) {
                return _c("v-row", { key: index, staticClass: "ma-0 pa-0" }, [
                  _vm._v("\n        " + _vm._s(message) + "\n      "),
                ])
              }),
              _c("v-row", { staticClass: "px-2 pt-2 pb-0" }, [
                _c("div", { staticClass: "input-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "input-item__label font-weight-bold",
                      staticStyle: {
                        width: "80vw",
                        "max-width": "480px",
                        display: "block",
                        float: "left",
                        "text-align": "center",
                      },
                      attrs: { id: "uploadBtn" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("button.uploadDocuments")) +
                          "\n            "
                      ),
                      _c("input", {
                        attrs: {
                          type: "file",
                          accept:
                            "image/jpeg,image/png,image/gif,application/pdf,.xls,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.ppt,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
                        },
                        on: { change: _vm.onFileUploaded },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "p",
                      {
                        staticClass: "my-0",
                        staticStyle: { "font-size": "10px" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.uploadLimitFileSize")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: { "font-size": "10px" },
                        attrs: { clsss: "mt-0" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.uploadableFileExtensions")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._l(_vm.docs, function (upload) {
                return _c(
                  "v-row",
                  { key: upload.name },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-0" },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "p",
                                  { staticClass: "float-left mr-3 my-1 pa-0" },
                                  [_vm._v("●" + _vm._s(upload.name))]
                                ),
                                _vm.checkImage(upload.contentType)
                                  ? [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-3 float-left",
                                          staticStyle: { "font-size": "20px" },
                                          attrs: {
                                            align: "center",
                                            dark: "",
                                            rounded: "",
                                            color: "next",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.preview(upload.file)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("button.preview"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "float-left",
                                    staticStyle: { "font-size": "20px" },
                                    attrs: {
                                      align: "center",
                                      width: "5vw",
                                      "max-width": "30px",
                                      dark: "",
                                      rounded: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.remove(upload)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("button.delete")))]
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "v-row",
                { staticClass: "my-12" },
                [
                  _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            align: "center",
                            width: "30vw",
                            "max-width": "170px",
                            color: "back",
                            dark: "",
                            rounded: "",
                          },
                          on: { click: _vm.onBackClicked },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.back")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            align: "center",
                            width: "30vw",
                            "max-width": "170px",
                            color: "next",
                            dark: "",
                            rounded: "",
                          },
                          on: { click: _vm.goNextPageConfirm },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.next")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Dialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showUploadErrorDialog,
                  title: null,
                  text: _vm.uploadErrorMessage,
                  subText: null,
                  negativeButtonTitle: null,
                  positiveButtonTitle: _vm.$t("button.close"),
                  onClickNegativeButton: null,
                  onClickPositiveButton: _vm.closeUploadErrorDialog,
                },
              }),
              _c("Dialog", {
                attrs: {
                  maxWidth: 350,
                  showDialog: _vm.showConfirmDialog,
                  title: null,
                  text: _vm.$t("description.nonUploadConfirm"),
                  subText: null,
                  negativeButtonTitle: _vm.$t("button.back"),
                  positiveButtonTitle: _vm.$t("button.next"),
                  onClickNegativeButton: _vm.closeConfirmDialog,
                  onClickPositiveButton: _vm.goNextPage,
                },
              }),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "70vw" },
                  model: {
                    value: _vm.previewDialog,
                    callback: function ($$v) {
                      _vm.previewDialog = $$v
                    },
                    expression: "previewDialog",
                  },
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: _vm.previewImg,
                      "max-width": "100%",
                      "max-height": "100%",
                      contain: true,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }