<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container style="width: 90%; max-height=90vh;" class="mx-auto">
        <v-row>
          <v-card-title class="font-weight-bold pb-3">
            {{ $t('title.contractor.uploadRequiredDocuments') }}
          </v-card-title>
        </v-row>
        <v-row class="pl-2">
          <p>{{ $t('description.requiredDocuments.explanation1') }}</p>
        </v-row>
        <v-row class="pl-2 font-weight-black">
          <p style="margin:3px">
            {{ $t('description.requiredDocuments.paper.title') }}
          </p>
        </v-row>
        <v-row class="pl-4">
          <p>
            {{ $t('description.requiredDocuments.paper.text') }}
          </p>
        </v-row>
        <v-row class="pl-2 font-weight-black">
          <p style="margin:3px">
            {{ $t('description.requiredDocuments.data.title') }}
          </p>
        </v-row>
        <v-row class="pl-4">
          <p>
            {{ $t('description.requiredDocuments.data.text') }}
          </p>
        </v-row>
        <v-row class="pl-2">
          <p>{{ $t('description.requiredDocuments.explanation2') }}</p>
        </v-row>
        <v-row class="pl-2">
          <p>{{ $t('description.requiredDocuments.backCaution') }}</p>
        </v-row>
        <v-row class="pl-2 pt-4 font-weight-bold">
          <p class="ma-0">{{ $t('label.requiredDocumentsList') }}</p>
        </v-row>
        <v-row
          v-for="(message, index) in requiredDocumentsMessage"
          :key="index"
          class="ma-0 pa-0"
        >
          {{ message }}
        </v-row>
        <v-row class="px-2 pt-2 pb-0">
          <div class="input-item">
            <label
              id="uploadBtn"
              style="width: 80vw; max-width: 480px; display: block; float: left; text-align: center;"
              class="input-item__label font-weight-bold"
            >
              {{ $t('button.uploadDocuments') }}
              <input
                type="file"
                @change="onFileUploaded"
                accept="image/jpeg,image/png,image/gif,application/pdf,.xls,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.ppt,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
              />
            </label>
          </div>
        </v-row>
        <v-row>
          <v-col>
            <p class="my-0" style="font-size:10px;">
              {{ $t('label.uploadLimitFileSize') }}
            </p>
            <p clsss="mt-0" style="font-size:10px;">
              {{ $t('label.uploadableFileExtensions') }}
            </p></v-col
          >
        </v-row>
        <v-row v-for="upload in docs" :key="upload.name">
          <v-container>
            <v-row class="my-0">
              <v-col>
                <p class="float-left mr-3 my-1 pa-0">●{{ upload.name }}</p>
                <template v-if="checkImage(upload.contentType)">
                  <v-btn
                    class="mr-3 float-left"
                    align="center"
                    style="font-size:20px"
                    dark
                    rounded
                    color="next"
                    @click="preview(upload.file)"
                    >{{ $t('button.preview') }}</v-btn
                  >
                </template>
                <v-btn
                  class="float-left"
                  align="center"
                  width="5vw"
                  max-width="30px"
                  style="font-size:20px"
                  dark
                  rounded
                  @click="remove(upload)"
                  >{{ $t('button.delete') }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-row>
        <v-row class="my-12">
          <v-col style="text-align:center">
            <v-btn
              class="font-weight-black"
              align="center"
              @click="onBackClicked"
              width="30vw"
              max-width="170px"
              color="back"
              style="font-size:20px"
              dark
              rounded
              >{{ $t('button.back') }}</v-btn
            >
          </v-col>
          <v-col style="text-align:center">
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              width="30vw"
              max-width="170px"
              color="next"
              style="font-size:20px"
              dark
              rounded
              @click="goNextPageConfirm"
              >{{ $t('button.next') }}</v-btn
            >
          </v-col>
        </v-row>
        <!-- アップロードエラーダイアログ -->
        <Dialog
          :maxWidth="320"
          :showDialog="showUploadErrorDialog"
          :title="null"
          :text="uploadErrorMessage"
          :subText="null"
          :negativeButtonTitle="null"
          :positiveButtonTitle="$t('button.close')"
          :onClickNegativeButton="null"
          :onClickPositiveButton="closeUploadErrorDialog"
        />
        <!-- 確認ダイアログ -->
        <Dialog
          :maxWidth="350"
          :showDialog="showConfirmDialog"
          :title="null"
          :text="$t('description.nonUploadConfirm')"
          :subText="null"
          :negativeButtonTitle="$t('button.back')"
          :positiveButtonTitle="$t('button.next')"
          :onClickNegativeButton="closeConfirmDialog"
          :onClickPositiveButton="goNextPage"
        />
        <v-dialog v-model="previewDialog" max-width="70vw">
          <v-img
            :src="previewImg"
            max-width="100%"
            max-height="100%"
            :contain="true"
          />
        </v-dialog>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Dialog from '@/components/organisms/contractor/Dialog.vue';
import { imageContentType } from '@/lib/const';
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  props: {
    docs: Array,
    onFileUploaded: Function,
    remove: Function,
    goNextPage: Function,
    goNextPageConfirm: Function,
    closeUploadErrorDialog: Function,
    closeConfirmDialog: Function,
    showUploadErrorDialog: Boolean,
    showConfirmDialog: Boolean,
    uploadErrorMessage: String,
  },
  components: { Dialog },
  data: () => ({
    requiredDocumentsMessage: [],
    previewDialog: false,
    previewImg: null,
  }),
  methods: {
    ...mapGetters('contract', ['selectedPlan', 'pageSum']),
    onBackClicked() {
      window.location.href = `/review_contract/${this.pageSum()}`;
    },
    checkImage(contentType) {
      return imageContentType.includes(contentType);
    },
    preview(src) {
      this.previewDialog = true;
      this.previewImg = src;
    },
  },
  async mounted() {
    this.requiredDocumentsMessage = splitMessageLine(
      this.selectedPlan().message
    );
  },
};
</script>
<style>
#uploadBtn > input {
  display: none;
}

#uploadBtn {
  padding: 0 1rem;
  border-radius: 25px;
  padding: 8px;
  background: #659d16;
  color: #ffffff;
}
</style>
